export const useSubStore = defineStore('subscribe-store', {
  
  persist: true,

  state: () => {
    return {
      country: null,
      email: null,
      fname: null,
      lname: null,
      subscriptionType: null,
      referral: null,
      utmSource: null,
      utmMedium: null,
      utmCampaign: null,
      utmLandingPage: null
    }
  },

  actions: {

    clearSubStore() {
      this.country = null
      this.email = null
      this.fname = null
      this.lname = null
      this.subscriptionType = null
      this.referral = null
    },

    addUtm(source, medium = null, campaign = null, landingPage = null) {
      this.utmSource = source
      this.utmMedium = medium
      this.utmCampaign = campaign
      this.utmLandingPage = landingPage
    },

  }
  
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSubStore, import.meta.hot))
}